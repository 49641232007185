import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as CommUtils from '@/utils/util'

import {
    Button,
    Toast,
    NavBar,
    Tab,
    Tabs,
    Cell,
    CellGroup
} from 'vant'

Vue.use(Button)
Vue.use(Toast)
Vue.use(NavBar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.config.productionTip = false
Vue.prototype.$CommUtils = CommUtils

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
