function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
}
const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

const deepClone = (target) => {
    let result = ''
    if (isObject(target)) {
        if (Array.isArray(target)) {
            result = []
            for (let i in target) {
                result.push(deepClone(target[i]))
            }
        } else if (target === null) {
            result = null
        } else if (target.constructor === RegExp) {
            result = target
        } else {
            result = {}
            for (let i in target) {
                result[i] = deepClone(target[i])
            }
        }
    } else {
        result = target
    }
    return result
}
//  blob 转 base64
const blobToDataURL = (blob, cb) => {
    let reader = new FileReader()
    reader.onload = function (evt) {
        let base64 = evt.target.result
        cb(base64)
    }
    reader.readAsDataURL(blob)
}
// base64 转 blob
const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
}

export { uuid, deepClone, blobToDataURL, dataURLtoBlob }
