<template>
    <div class="home">
        <img alt="Vue logo" src="../assets/images/logo.png">
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
    },
    data () {
        return {

        }
    },
    methods: {
        getList () {
        }
    },
    mounted () {
        this.getList()
    }
}
</script>
<style scoped>
</style>
